<template>
  <div>

    <!-- User Info: Input Fields -->
    <validation-observer ref="editAccountValidation">
      <b-form>
        <b-row>

          <!-- Field: Full Name -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Name"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="userData.name"
                autocomplete="off"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Email -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userData.email"
                  type="email"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Phone -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Phone"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="userData.phone"
                autocomplete="off"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <!-- Field: Password -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Password"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="userData.password"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    autocomplete="off"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <!-- Field: API Key -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="API Key"
              label-for="api-key"
            >
              <b-form-input
                v-model="myToken"
                input-id="api-key"
                autocomplete="off"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-button
              variant="primary"
              class="mt-2 mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="generateNewApiKey"
            >
              Generate a new key
            </b-button>
          </b-col>
        </b-row>
      </b-form>

      <eds-edit
        v-if="countryCode === 'lv'"
        :event-hub="eventHub"
        :country-code="countryCode"
        :user-id="userId"
        :company-id="company.id"
      />

      <user-settings-edit
        v-if="canEditUserSettings"
        :event-hub="eventHub"
        :country-code="countryCode"
        :user-id="userId"
        :company-id="company.id"
      />
    </validation-observer>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="validationForm"
    >
      Save Changes
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import handleError from '@/views/components/errorHandler'
import EdsEdit from '@/views/users/EdsEdit.vue'
import UserSettingsEdit from '@/views/users/UserSettingsEdit.vue'
import Vue from 'vue'

export default {
  components: {
    UserSettingsEdit,
    EdsEdit,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroupAppend,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      myToken: null,
      required,
      email,
      eventHub: new Vue(),
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    userData() {
      if (this.$profile().data.user) {
        return this.$profile().data.user
      }
      return {
        password: null,
        email: null,
        name: null,
        phone: null,
        token: null,
      }
    },
    userId() {
      return this.$profile().data.user.id
    },
    countryCode() {
      return this.$activeCompany().data.company.country.toLowerCase()
    },
    canEditUserSettings() {
      const h1 = this.$classifiers().getClassifier('h1_countries')
      if (h1 && Object.keys(h1).length > 0) {
        return this.$profile().data.user.companies.some(company => h1.includes(company.country) && company.config?.h1)
      }
      return false
    },
    company() {
      return this.$activeCompany().data.company
    },
  },
  methods: {
    validationForm() {
      this.$refs.editAccountValidation.validate().then(success => {
        if (!success) {
          return
        }
        this.editAccount()
      })
    },
    generateNewApiKey() {
      this.$http.get('/v1/users/generate-key')
        .then(response => {
          this.myToken = response.data.token
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully generated a new api key.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
    editAccount() {
      if (this.canEditUserSettings || this.countryCode === 'lv') {
        this.eventHub.$emit('saveCustomsSettings')
      }
      this.$http.put('/v1/users', {
        email: this.userData.email,
        name: this.userData.name,
        password: this.userData.password,
        phone: this.userData.phone,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully modified your account',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
  },
  setup(props) {
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    return {
      avatarText,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
</style>
