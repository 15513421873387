<template>
  <b-card>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>
    <template #title>
      <feather-icon
        icon="UserIcon"
        size="16"
        class="mr-0 mr-sm-50"
      />
      <span class="d-none d-sm-inline">Account</span>
    </template>
    <user-edit-tab-account
      :user-data="userData"
      class="mt-2 pt-75"
    />
  </b-card>
</template>

<script>
import {
  BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import userStoreModule from '../userStoreModule'
import UserEditTabAccount from './EditAccount.vue'

export default {
  components: {
    BCard,
    BAlert,
    BLink,

    UserEditTabAccount,
  },
  data() {
    return {
      userData: this.$profile().data.user,
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
  },
}
</script>

<style>

</style>
