<template>
  <b-card
    no-body
    class="border mt-1"
  >
    <b-card-header class="p-1">
      <b-card-title class="font-medium-2">
        <feather-icon
          icon="SettingsIcon"
          size="18"
        />
        <span class="align-middle ml-50">EDS user settings</span>
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="EDS Username"
            label-for="eds-username"
          >
            <validation-provider
              #default="{ errors }"
              name="Eds Username"
              rules="required"
            >
              <b-form-input
                id="eds-username"
                v-model="edsSettings.edsUser"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="EDS Password"
            label-for="eds-password"
          >
            <validation-provider
              #default="{ errors }"
              name="Eds Password"
              rules="required"
            >
              <b-form-input
                id="eds-password"
                v-model="edsSettings.edsPassword"
                :type="'password'"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BCardHeader, BCardTitle, BCol, BFormGroup, BFormInput, BRow } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import handleError from '@/views/components/errorHandler'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardHeader,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    ValidationProvider,
  },
  props: {
    eventHub: {
      default: () => new Vue(),
    },
    countryCode: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    companyId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      edsSettings: {
        user: '',
        password: '',
      },
    }
  },
  created() {
    this.eventHub.$on('saveCustomsSettings', () => {
      this.saveChanges()
    })
    this.fetchUserCustomsData()
  },
  methods: {
    saveChanges() {
      this.$http.post(`/v1/companies/${this.companyId}/user/${this.userId}/customs/${this.countryCode}`, this.edsSettings)
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
    fetchUserCustomsData() {
      this.$http.get(`/v1/companies/${this.companyId}/user/${this.userId}/customs/${this.countryCode}`)
        .then(response => {
          switch (this.countryCode) {
            case 'lv':
              this.$data.edsSettings = response.data
              break
            default:
              break
          }
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
  },
}
</script>
